import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "the-legacy-of-uniswap-v3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-legacy-of-uniswap-v3",
        "aria-label": "the legacy of uniswap v3 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Legacy of Uniswap V3`}</h1>
    <p>{`Uniswap V3’s initial launch was nothing short of revolutionary for the world of Decentralized Finance (DeFi). Its innovative features, unparalleled transparency, and market-driven design propelled the DeFi space into a new age of opportunities, demonstrating the robustness and potential of this platform.`}</p>
    <h1 {...{
      "id": "introducing-pegasysv3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introducing-pegasysv3",
        "aria-label": "introducing pegasysv3 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introducing PegasysV3`}</h1>
    <p>{`We are proud to introduce PegasysV3, the new iteration of our platform that carries the legacy of Pegasys v1 while incorporating many exciting improvements:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Concentrated Liquidity`}</strong>{`, offering unprecedented control to liquidity providers.`}</li>
      <li parentName="ul"><strong parentName="li">{`Range Orders`}</strong>{`, providing the opportunity to trade within a specific price range, and more.`}</li>
      <li parentName="ul"><strong parentName="li">{`Advanced Oracles`}</strong>{`, which make the integration of Pegasys’s data feeds easier and more cost-effective.`}</li>
      <li parentName="ul"><strong parentName="li">{`Multiple Fee Tiers`}</strong>{`, which allow liquidity providers to be duly compensated for taking on varying degrees of risk.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/07bfb50e4f07f12d8058bcf63e133ee3/29007/banner1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAADJklEQVQozwXBbVDTdQDA8R+M/zZQsT3wMA4dHjuYwLEaJzAmT05hGGMMtoB4CHkauLZYjYHQ2ggO2jKKODVTO4adaad153Fdl0dXIl7pG73z6s7OOq9e9Mbuqtfdt89HyIvCyA44SS2sQpRWkFpejqy6AullB4rF19h1JoZqY5Wc+AK588vsnV6h8t1vqJ/eonVkG5f9eyL9j1hu+Y246Q+E0I6TbQ9S4BjA0Owky2ZDZj2E1FCFZKtG7u1CsRRGsTBHSpsLbShO/OE/WP1XKGy8QLP9K6b6HhCq+4lo1WNEes4MMtMwqWYbiqrDSLUWRGMlosWCaLUiTZ1AiofZ82WS9PlFlP4YTRe26Fm9w/6mc/R6bzM5/JDemi1sOWcRe3UL5JaHKagfJs/mQXvcQU6Hi/zRIfSBk6hnAuxbPU3BB2soF5fIP30RzetrGIOfYuy5hGP8FoHQz/S5blFnOI/I0LyJs/0SS3ObnApeJzS1TmTuKr5IklDsGseXP2b0zE0in9zn4LUvKPv8ayznN+le/wGT/ypFrot4Ajtcuf4XPt9dxC5dBGP9FGaXF0vPGM5gmMJ2F7vH+lAvzaI+l0B94zJdT35h4OnvHPn2Rz789Rk7z/4jsfMn7YnbRJKPObm4jdYwgVDmTZNj9pFtcaM66qagswtFbQ2pnjakSBApHkW5lsByfwfrnbsc+e4e80//5v0n/zKy+Yj8kVVe8G+gPTpH2r46RNbBt5EbvCjKOhGVLoSlGVFdh9T3CopolIxTYZT9vezfSKJ5K0b+e2cxX76J/cY2ZYvryJpHyeyJITO7yLKNI0yNK4j8QXKtY2Tb/YhDDkxjITR9Xp6biZHnDyLr9KAITCJztJPRe4IUzwAF73xExUqSDM8EuuAK8lILutpWhFz3KkLXjUJ/jGLHJOklDRiOdVD40iiFQwFSbC9SMhtFOziMsq0DudONxulG3T2IZiSIcTZB2RsLPN8/jspYjsjU+5Blt6IuakE60ECa3kxanoE0gwlhqmFi/TOW7z1gj7ubUv8kmYfrEYZiSnsG0A/5UHX0o2pysrvCSnZxCf8D1My9wnEg9IYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "banner1",
            "title": "banner1",
            "src": "/static/07bfb50e4f07f12d8058bcf63e133ee3/c1b63/banner1.png",
            "srcSet": ["/static/07bfb50e4f07f12d8058bcf63e133ee3/5a46d/banner1.png 300w", "/static/07bfb50e4f07f12d8058bcf63e133ee3/0a47e/banner1.png 600w", "/static/07bfb50e4f07f12d8058bcf63e133ee3/c1b63/banner1.png 1200w", "/static/07bfb50e4f07f12d8058bcf63e133ee3/29007/banner1.png 1600w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1 {...{
      "id": "understanding-concentrated-liquidity",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#understanding-concentrated-liquidity",
        "aria-label": "understanding concentrated liquidity permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Understanding Concentrated Liquidity`}</h1>
    <p>{`In the first version of our AMM, when you become a liquidity provider (LP), you supply liquidity across the entire price spectrum. This means that your assets can be used to facilitate any trade, irrespective of the current price of the tokens involved.`}</p>
    <p>{`One of the game-changing features of PegasysV3 is Concentrated Liquidity. This system allows liquidity providers to allocate their token pairs within specific price ranges, rather than across the entire price spectrum. For instance, if you anticipate the $PSYS/USDT pair to trade between 0.2 and 0.4, you can concentrate your liquidity within this range to maximize yield.`}</p>
    <p>{`Thus, Pegasys V3 allows you to have more control and the potential for higher returns but requires more active management. As an LP, you should monitor price movements and adjust your price range as necessary to maximize your returns.`}</p>
    <p>{`In more practical terms, as a liquidity provider, you have an additional step where you specify the price range within which your tokens will be used for trades. This means an added layer of decision-making and potentially more management, as you may need to adjust this range based on how the token price moves over time. However, the UX design of our platform guides you through this process, making it straightforward.`}</p>
    <p>{`We highly suggest you check out the following video by Finematics, which explains this system in more in-depth:`}</p>
    <p><iframe parentName="p" {...{
        "width": "100%",
        "height": 315,
        "src": "https://www.youtube-nocookie.com/embed/Ehm-OYBmlPM?rel=0",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
        "allowFullScreen": true
      }}></iframe></p>
    <h2 {...{
      "id": "ranged-orders",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ranged-orders",
        "aria-label": "ranged orders permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Ranged Orders`}</h2>
    <p>{`In PegasysV3, liquidity providers can concentrate their funds around the price ranges they consider most likely, increasing their capital utilization. Range orders allow liquidity providers to set up orders that execute when a certain price is reached, essentially functioning like limit orders on traditional exchanges. In PegasysV3, liquidity positions are represented as Non-Fungible Tokens (NFTs), which means each liquidity position is unique and can be traded, leveraged, or used in innovative ways.`}</p>
    <h2 {...{
      "id": "advanced-oracles",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#advanced-oracles",
        "aria-label": "advanced oracles permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Advanced Oracles`}</h2>
    <p>{`Advanced Oracles in PegasysV3 are the foundation for more efficient data integration. In traditional finance and DeFi alike, accurate and up-to-date price information is key. PegasysV3 uses an enhanced version of Pegasys V1 time-weighted average price (TWAP) Oracles. These Advanced Oracles provide highly reliable, low-cost, and easily integratable price feeds.`}</p>
    <p>{`Essentially, they store historical price data directly on-chain, allowing users and smart contracts to access past market prices for any period within the last nine days. This gives DeFi protocols and DApps the ability to calculate accurate price averages, providing a more secure and dependable environment for users.`}</p>
    <h2 {...{
      "id": "embracing-flexible-fees",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#embracing-flexible-fees",
        "aria-label": "embracing flexible fees permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Embracing Flexible Fees`}</h2>
    <p>{`The introduction of flexible fees in PegasysV3 is a boon for users. Unlike the fixed fees of the previous version, flexible fees allow users to choose the level of risk they are comfortable with, and be appropriately rewarded for it. This brings a new level of customization to DeFi, enabling you to tailor your DeFi experience to your risk tolerance and financial goals.`}</p>
    <h1 {...{
      "id": "the-power-of-rollux",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-power-of-rollux",
        "aria-label": "the power of rollux permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Power of Rollux`}</h1>
    <p>{`PegasysV3 is built on `}<a parentName="p" {...{
        "href": "https://rollux.com/"
      }}>{`Rollux`}</a>{`, an official optimistic rollup launched by the Syscoin Foundation. The benefits of using Rollux over Syscoin are manifold, including lower transaction costs, faster confirmation times, and increased scalability. Rollux offers a secure and efficient platform that provides the best user experience in DeFi.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/309a3c9b7985bdfb00a3db68bab52d5d/1ffbd/rollux.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABSUlEQVQoz62Ry07CYBCFeSrfxcS1L+BDGE0kMWC8btwYjQs3ukRElEtAIF6ggmAAMZH+balYrNCfz9QiVoMrPcnJLGbmzJyZgOM4+CkdeOnfY75dIOUAKYcMhxI/fvb4GfgqG36IuQhuzjA7N4VSP2Rv94Bo9BhFUYhEIh+U8vsAPwLu9E9Kx1M8OllgPjTNo1ogm8mRyaRJpZLET2MUCjkcZzDqcXzRdYJ/w7/DFQ1Y7r3sAl37EqObQxhZNCOLEBl0PY2mpdFEEtNIYYgEhppAb59jtM+w9AQ9I0FPxBi8tjzLWi9Oy9zh6Xmf6sM2V8oGt9UtlPI6irJGqbhC6SZMo7ZKpRiicr1MKR+knF9CrYXo1MPod4vYnZwn+O+WvWNOpm3bWJY1jv1+38tJObF+9OVJCS8KIWg2m6hqm0ajgWma401+E3wHtq2qQ1XMNYYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "rollux",
            "title": "rollux",
            "src": "/static/309a3c9b7985bdfb00a3db68bab52d5d/c1b63/rollux.png",
            "srcSet": ["/static/309a3c9b7985bdfb00a3db68bab52d5d/5a46d/rollux.png 300w", "/static/309a3c9b7985bdfb00a3db68bab52d5d/0a47e/rollux.png 600w", "/static/309a3c9b7985bdfb00a3db68bab52d5d/c1b63/rollux.png 1200w", "/static/309a3c9b7985bdfb00a3db68bab52d5d/d61c2/rollux.png 1800w", "/static/309a3c9b7985bdfb00a3db68bab52d5d/1ffbd/rollux.png 2108w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1 {...{
      "id": "community-engagement-and-governance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#community-engagement-and-governance",
        "aria-label": "community engagement and governance permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Community Engagement and Governance`}</h1>
    <p>{`Community engagement is at the heart of the Syscoin Foundation’s ethos, and PegasysV3 is no different. The platform is designed to foster a sense of ownership among its users, encouraging everyone to get involved in the `}<a parentName="p" {...{
        "href": "http://gov.pegasys.finance"
      }}>{`governance of the protocol `}</a>{`. This democratic approach ensures the platform stays transparent, responsive, and inclusive.`}</p>
    <p>{`We understand that a strong community is the backbone of any successful DeFi platform. That’s why we’ve created multiple avenues for our users to connect, share, and learn. For any assistance or queries, our dedicated support team is just a click away, join our `}<a parentName="p" {...{
        "href": "https://discord.com/invite/UzjWbWWERz"
      }}>{`discord`}</a>{`!`}</p>
    <h1 {...{
      "id": "new-data-analytics-platform",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#new-data-analytics-platform",
        "aria-label": "new data analytics platform permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New data analytics platform`}</h1>
    <p>{`With the launch of PegasysV3, we are excited to also introduce a brand new `}<a parentName="p" {...{
        "href": "http://info.pegasys.fi"
      }}>{`analytics platform`}</a>{` and `}<a parentName="p" {...{
        "href": "http://docs.pegasys.fi"
      }}>{`comprehensive documentation`}</a>{`. These resources will provide valuable insights, offer educational materials, and ensure you’re up-to-date with the latest developments, helping you to navigate PegasysV3 with ease.`}</p>
    <h1 {...{
      "id": "launch-details",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#launch-details",
        "aria-label": "launch details permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Launch Details`}</h1>
    <p>{`PegasysV3 smart contracts are deployed to Rollux and all code can be found on `}<a parentName="p" {...{
        "href": "http://github.com/Pegasys-fi"
      }}>{`Github`}</a>{`.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/pegasys-fi/v3-core"
      }}>{`Pegasys v3 Core repository`}</a>{` contains the essential, low-level smart contracts that power the protocol.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/pegasys-fi/v3-periphery"
      }}>{`Pegasys v3 Periphery repository`}</a>{` contains a collection of smart contracts designed to ease user interaction with core contracts.`}</p>
    <p>{`As we prepare for the grand launch of PegasysV3, we understand the importance of smooth transitions and comprehensive collaborations. Our core team is hard at work, coordinating with key partners to ensure seamless migration of liquidity from Layer 1 to our upgraded platform. We are excited to collaborate with brilliant minds at `}<a parentName="p" {...{
        "href": "https://www.gamma.xyz/"
      }}>{`Gamma`}</a>{`, `}<a parentName="p" {...{
        "href": "https://app.befyy.finance/"
      }}>{`Beefy`}</a>{`, and other prominent DeFi players, leveraging their expertise to amplify our capabilities.`}</p>
    <p>{`In our commitment to provide continuous value to our users, we’re laying the groundwork for launching liquidity incentive programs. We understand that liquidity is the lifeblood of any DeFi platform, and these programs are designed to attract, reward, and retain our invaluable liquidity providers.`}</p>
    <p>{`Pegasys V3 aims to provide an enriched DeFi experience, characterized by improved efficiency, inclusivity, and opportunities. We encourage you to keep an eye on our official channels for the latest news and developments.`}</p>
    <h1 {...{
      "id": "exciting-features-on-the-horizon",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#exciting-features-on-the-horizon",
        "aria-label": "exciting features on the horizon permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Exciting Features on the Horizon`}</h1>
    <p>{`The launch of PegasysV3 is only the starting point of our vision. We’re currently developing another DeFi primitive, set to bring key functionalities to Rollux and NEVM Layer one. This new offering will further expand the capabilities of our platform, ensuring that PegasysV3 remains at the forefront of the DeFi revolution.`}</p>
    <p>{`Our governance portal has never been busier, and we encourage everyone to participate. The Pegasys DAO is a testament to our commitment to a truly decentralized and democratic financial system. Your voice matters, and your participation helps shape the future of our platform.`}</p>
    <p>{`Finally, as we focus on bringing you the best that Rollux has to offer, we also recognize the importance of our roots. The Layer 1 user interface will continue to be live and will primarily be used for on-chain governance. We are working diligently to port over the fantastic features developed on our original UI to this new release, ensuring that you continue to enjoy a familiar yet enhanced experience.`}</p>
    <p>{`These are truly exciting times in the world of DeFi, and we’re thrilled to have you with us on this journey.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      